<template>
  <div style="text-align: left">

    <div class="">
      <div class="row">
        <div class="col-md-12 mt-3 mb-3">
          <span class="text-muted">Section | </span><span>{{ capitalizeWords(sectionName) }}</span>
        </div>
      </div>

      <!-- Slider Start -->
      <!-- <section class="iq-main-slider p-0">
        <div id="tvshows-slider">
          <div v-for="p in filteredPosts" v-bind:key="p._id">
            <router-link :to="{ name: 'Post', params: { postId: p.id } }">
              <a href="#">
                <div class="shows-img">
                  <img v-bind:src="p.Thumbnail" class="w-100" alt="" />
                  <div class="shows-content">
                    <h4 class="text-white mb-1">{{ p.Title }}</h4>
                    <div class="movie-time d-flex align-items-center">
                      <div class="badge badge-secondary p-1 mr-2"></div>
                      <span class="text-white">{{ p.Tagline }}</span>
                    </div>
                  </div>
                </div>
              </a>
            </router-link>
          </div>
        </div>

      </section> -->
      <!-- Slider End -->
    </div>

    <section
      id="iq-favorites"
      v-for="(subsection, i) in currentSection.subsections"
      v-bind:key="i"
      class=" mx-auto"
    >
      <h4
        class="main-title mt-4"
        style="border: 1pm solid #ccccccc"
        v-if="getSubsectionPosts(subsection).length"
      >{{capitalizeWords(subsection)}}</h4>

      <div class="row">
        <div
          class="col-md-3"
          v-for="post in getSubsectionPosts(subsection)"
          :key="post.id"
        >
          <post-card :post="post"></post-card>

        </div>
      </div>
      <!-- <slider :title="capitalizeWords(subsection)" :posts="getSubsectionPosts(subsection)"></slider> -->
    </section>
  </div>
</template>

<script>
import { db } from "@/db.js";
import PostCard from "../components/PostCard.vue";
import _ from "lodash";
// import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
// import Slider from '../components/Slider.vue';


export default {
  name: "Home",
  components: { PostCard },
  data: function () {

    return {
      posts: [],
      sections: [],
      filteredPosts: [],
      currentSection: {},
      sectionName: "",
      subSectionName: "",

      settings: {
        dots: true,
        infinite: false,
        speed: 300,
        slidesToShow: 5,
        slidesToScroll: 5,
        responsive: [
          {
            breakpoint: 1160,
            settings: {
              slidesToShow: 5,
              slidesToScroll: 5,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 960,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 4,
            },
          },
          {
            breakpoint: 730,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            },
          },

          // You can unslick at a given breakpoint now by adding:
          // settings: "unslick"
          // instead of a settings object
        ],
      },
    };
  },
 watch: {
    '$store.state.currentUser.membershipLevel': {
 
      immediate: true,
      handler: "pageLoad",
    },
  },

  mounted () {
    if (this.$store.state.currentUser.membershipLevel) {
      this.pageLoad()
    }

  },
  methods: {
    pageLoad () {
      this.getPosts()
      this.getSections();


      if (this.$route.params.sectionName) {
        this.sectionName = this.$route.params.sectionName;
      }
      if (this.$route.params.subSectionName) {
        this.subSectionName = this.$route.params.subSectionName;
      }
    },

    // slider (className) {
    //   jQuery(className).slick({
    //     dots: false,
    //     arrows: true,
    //     infinite: true,
    //     speed: 300,
    //     autoplay: false,
    //     slidesToShow: 4,
    //     slidesToScroll: 4,
    //     // appendArrows: $('#sm-slick-arrow'),

    //     nextArrow:
    //       '<a href="#" class="slick-arrow slick-next"><i class= "fa fa-chevron-right"></i></a>',
    //     prevArrow:
    //       '<a href="#" class="slick-arrow slick-prev"><i class= "fa fa-chevron-left"></i></a>',
    //     responsive: [
    //       {
    //         breakpoint: 1400,
    //         settings: {
    //           slidesToShow: 12,
    //           slidesToScroll: 12,
    //           infinite: true,
    //           dots: true,
    //         },
    //       },
    //       {
    //         breakpoint: 1100,
    //         settings: {
    //           slidesToShow: 6,
    //           slidesToScroll: 6,
    //           infinite: true,
    //           dots: true,
    //         },
    //       },
    //       {
    //         breakpoint: 768,
    //         settings: {
    //           slidesToShow: 2,
    //           slidesToScroll: 2,
    //         },
    //       },
    //       {
    //         breakpoint: 480,
    //         settings: {
    //           // arrows: false,
    //           slidesToShow: 1,
    //           slidesToScroll: 1,
    //         },
    //       },
    //     ],
    //   });
    // },
    capitalizeWords (str) {
      switch (str) {
        case "djing":
          return "DJing";
          break;
        case "mcing":
          return "MCing";
          break;
        case "website & seo webinars":
          return "Website & SEO Webinars";
          break;
        case "sales tips":
          return "Sales & Pricing Tips"
          break;
        case "PhDJ Workshop Seminars":
          return "PhDJ Workshop Seminars";
          break;
        default:
          return str.replace(/\w\S*/g, function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
          });
      }
    },

    getPosts () {

      if (this.$store.state.currentUser.membershipLevel == 'lite') {

        this.$bind(
          "posts",
          db.collection("posts").orderBy("PublishedDate", "desc")
            .where("lite", "==", true)
        ).then(() => {
          this.filterPosts();
        });
      } else {
        this.$bind(
          "posts",
          db.collection("posts").orderBy("PublishedDate", "desc")
        ).then(() => {
          this.filterPosts();
        });
      }






    },
    getSubsectionPosts (subsection) {
      //  console.log(subsection, this.filteredPosts);
      return this.filteredPosts.filter((post) => post.Subsection == subsection);
    },

    getSections () {
      this.$bind("sections", db.collection("sections")).then(() => {
        if (this.sectionName) {
          for (let section of this.sections) {
            if (section.section == this.sectionName) {
              this.currentSection = section;
              break;
            }
          }
        }
      });
    },

    filterPosts () {
      this.filteredPosts = [];
      for (let post of this.posts) {
        const postSection = post.Section.toString().toLowerCase();
        const postSubSection = post.Subsection.toString().toLowerCase();

        if (
          postSection == this.sectionName.toLowerCase() &&
          (this.subSectionName == "" || postSubSection == this.subSectionName)
        )


          this.filteredPosts.push(post);


      }

      // this.$nextTick(() => {
      //   jQuery("#tvshows-slider").slick({
      //     centerMode: true,
      //     centerPadding: "200px",
      //     slidesToShow: 1,
      //     nextArrow:
      //       '<button class="NextArrow"><i class="ri-arrow-right-s-line"></i></button>',
      //     prevArrow:
      //       '<button class="PreArrow"><i class="ri-arrow-left-s-line"></i></button>',
      //     arrows: true,
      //     dots: false,
      //     responsive: [
      //       {
      //         breakpoint: 991,
      //         settings: {
      //           arrows: false,
      //           centerMode: true,
      //           centerPadding: "20px",
      //           slidesToShow: 1,
      //         },
      //       },
      //       {
      //         breakpoint: 480,
      //         settings: {
      //           arrows: false,
      //           centerMode: true,
      //           centerPadding: "20px",
      //           slidesToShow: 1,
      //         },
      //       },
      //     ],
      //   });
      // });
    },

    onSubSectionSelected (subsection) {
      this.$router.push(`/section/${this.sectionName}/${subsection}`);
    },

    // onPostClick(post) {
    //   this.$router.push('/post/${post.id}');

    // },

    // getSubSections() {
    //   this.sections.forEach(function (section) {
    //     db.collection("posts")
    //       .where("Section", "==", section.section)
    //       .get()
    //       .then((querySnapshot) => {
    //         const posts = querySnapshot.docs.map((doc) => doc.data());
    //       console.log(section.section)
    //       let subsectionsArr = []
    //         posts.forEach(function (post) {

    //           if (post.Subsection){

    //             subsectionsArr.push(post.Subsection)

    //            let subsectionsUniq = _.uniqBy(subsectionsArr);
    //             console.log(subsectionsUniq)
    //           }

    //         });
    //       });
    //   });
    // },
  },
};
</script>

<style scoped>
h3 {
  text-align: center;
  margin-top: 50px;
}

.slide-item {
  margin-bottom: 20px;
}

.sub-section-dropdown {
  margin-left: 25px;
}

#iq-main-slider {
  max-width: 1280px;
  margin: auto;
}

.overflow-hidden {
  overflow: unset;
}
</style>